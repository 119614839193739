<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <div class="input-group">
                    <datepicker input-class="form-control transparent" placeholder="Start Date"
                      @input="doFilterDate"
                      class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="filter.startDate"
                      >
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>  
                </b-col>
                <b-col lg="4">
                  <div class="input-group">
                    <datepicker input-class="form-control transparent" placeholder="End Date"
                        class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="filter.endDate"
                        @input="doFilterDate"
                        :disabledDates="{ to: new Date(filter.startDate) }">
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </b-col>
              </b-form-row>
            </b-col>
              <b-col lg="4">
                <SearchInput :value.sync="filter.search" @search="doFill" />
              </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="listData||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!listData"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(number)="v">
              {{v.index+1}}
            </template>
            <template #cell(action)="data">
              <div class="list-icons">
                  <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('download')"
                  @click="download('', {exptype: 'xlsx', tab: data.item.slug, sd:filter.startDate, end:filter.endDate, search: filter.search})"
                  data-toggle="tooltip" data-placement="top" title="Download"><i
                    class="icon-box-add"></i></a>
              </div>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(listData||[]).length&&data.total>10">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
const _ = global._
const moment = require('moment')

export default {
  extends: GlobalVue,
  components:{ Datepicker },
  data() {
    return {
      idKey:'id',
      statusKey:'is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'name',
          label: 'Report Name',
        },
        {
          key: 'action',
        },
      ],
      listData:[
          {
            name : "Report SLA",
            slug : "report-sla" 
          }
      ]
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFilterDate(){
        this.filter.startDate = moment(this.filter.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.filter.endDate).format('YYYY-MM-DD')
    },
    doFill(){
        this.doFilter()
    },
  }
}
</script>